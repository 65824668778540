import React from 'react';

export const Info = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 0H1.75C1.41848 0 1.10054 0.131696 0.866116 0.366116C0.631696 0.600537 0.5 0.918479 0.5 1.25V13.75C0.5 14.0815 0.631696 14.3995 0.866116 14.6339C1.10054 14.8683 1.41848 15 1.75 15H14.25C14.5815 15 14.8995 14.8683 15.1339 14.6339C15.3683 14.3995 15.5 14.0815 15.5 13.75V1.25C15.5 0.918479 15.3683 0.600537 15.1339 0.366116C14.8995 0.131696 14.5815 0 14.25 0ZM8 2.5C8.18542 2.5 8.36668 2.55498 8.52085 2.658C8.67502 2.76101 8.79518 2.90743 8.86614 3.07873C8.93709 3.25004 8.95566 3.43854 8.91949 3.6204C8.88331 3.80225 8.79402 3.9693 8.66291 4.10041C8.5318 4.23152 8.36475 4.32081 8.1829 4.35699C8.00104 4.39316 7.81254 4.37459 7.64123 4.30364C7.46993 4.23268 7.32351 4.11252 7.2205 3.95835C7.11748 3.80418 7.0625 3.62292 7.0625 3.4375C7.0625 3.18886 7.16127 2.9504 7.33709 2.77459C7.5129 2.59877 7.75136 2.5 8 2.5ZM10.5 12.5781H5.5V11.1719H7.29688V7.57812H6.125V6.17188H8.70312V11.1719H10.5V12.5781Z"
      fill="#009ACE"
    />
  </svg>
);
