import React from 'react';

export const ProfileBadge = () => (
  <svg
    width="47"
    height="36"
    viewBox="0 0 47 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5.61719"
      y="8"
      width="41"
      height="28"
      rx="2.49412"
      fill="#D4F0FF"
    />
    <path
      d="M37.3828 3.375H5.38281C2.85011 3.375 0.757812 5.36049 0.757812 7.85714V27.1429C0.757812 29.6395 2.85011 31.625 5.38281 31.625H37.3828C39.9155 31.625 42.0078 29.6395 42.0078 27.1429V7.85714C42.0078 5.36049 39.9155 3.375 37.3828 3.375Z"
      stroke="black"
      strokeOpacity="0.94"
      strokeWidth="1.25"
    />
    <rect
      x="14.0078"
      y="0.625"
      width="14.75"
      height="4.75"
      rx="1.875"
      fill="white"
      stroke="black"
      strokeWidth="1.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0739 12C12.2546 12 10.7798 13.4748 10.7798 15.2941C10.7798 17.1134 12.2546 18.5882 14.0739 18.5882C15.8932 18.5882 17.3681 17.1134 17.3681 15.2941C17.3681 13.4748 15.8932 12 14.0739 12ZM19.0152 25.9605L19.0153 26.0002H19.0152V26.0003H9.13281L9.13295 25.9607V23.3237C9.13295 21.6181 10.5156 20.2355 12.2212 20.2355H13.2503H13.2506H15.7209V20.2356H15.9269C17.6325 20.2356 19.0152 21.6182 19.0152 23.3238V25.9605Z"
      fill="#009ACE"
    />
    <line
      x1="23.2852"
      y1="15.25"
      x2="34.6327"
      y2="15.25"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="23.1328"
      y1="20.25"
      x2="34.6328"
      y2="20.25"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
