import axios, { AxiosInstance } from 'axios';

import { IBffEnableEmailData } from '../../pages/api/ciam/mfa/email';
import { IBffEnablePhoneData } from '../../pages/api/ciam/mfa/phone';
import { IBffVerifyMfa } from '../../pages/api/ciam/mfa/verify';
import { IBffChangePasswordData } from '../../server/schemas';
import { extractData } from '../BffClient/extractors';
import { extractNothing } from './extractors';
import { extractError } from './extractors/extractError';
import { extractOob } from './extractors/extractOob';
import { IChangeUsernameForm } from '../../components/organisms/ChangeUsernameForm';
import { IBffChangeUsernameData } from '../../server/schemas/changeUsernameSchema';
import { IBffValidateOtpData } from '../../server/schemas/validateOTPSchema';
import { IBffChangeContactEmailData } from '../../server/schemas/changeContactEmailSchema';

export class CiamClient {
  private client: AxiosInstance;
  public businessUnit = '';
  constructor() {
    this.client = axios.create({
      baseURL: '/api',
    });
  }

  userinfo = (businessUnit: string) =>
    singleton(() =>
      this.client
        .post('/ciam/userinfo', { businessUnit })
        .then(extractData, extractError),
    );

  mfa = (businessUnit: string) =>
    singleton(() =>
      this.client
        .post('/ciam/mfa', { businessUnit })
        .then(extractData, extractError),
    );
  changePassword = (data: IBffChangePasswordData) =>
    this.client
      .post('/ciam/password/change', { ...data })
      .catch(extractError)
      .then(extractNothing);

  changeContactEmail = (data: IBffChangeContactEmailData) =>
    this.client
      .post('/ciam/contact-email/change', { ...data })
      .catch(extractError)
      .then(extractNothing);

  checkIfUsernameIsBeingUsed = (newUsername: string, businessUnit: string) =>
    this.client
      .post('ciam/username/check-username', { newUsername, businessUnit })
      .catch(extractError)
      .then(extractData);

  sendChangeContactVerifyEmail = (data: IBffChangeContactEmailData) =>
    this.client
      .post('/ciam/contact-email/send-verify', { ...data })
      .catch(extractError)
      .then(extractData);

  changeUsername = (data: IChangeUsernameForm) =>
    this.client
      .post('/ciam/username/change', { ...data })
      .catch(extractError)
      .then(extractNothing);

  sendChangeUsernameVerifyEmail = (data: IBffChangeUsernameData) =>
    this.client
      .post('/ciam/username/send-verify', { ...data })
      .catch(extractError)
      .then(extractData);

  validateEmailOtpCode = (data: IBffValidateOtpData) =>
    this.client
      .post('/ciam/username/verify-code', { ...data })
      .catch(extractError)
      .then(extractData);

  removeMfa = (data: { id: string }) =>
    this.client
      .delete('/ciam/mfa', { data })
      .then(extractNothing, extractError);

  removeAllMfaMethods = (businessUnit: string) =>
    this.client
      .delete('/ciam/mfa/mfas', { data: { businessUnit: businessUnit } })
      .then(extractNothing, extractError);

  enableEmail = (data: IBffEnableEmailData) =>
    this.client.post('/ciam/mfa/email', data).then(extractOob, extractError);

  enablePhone = (data: IBffEnablePhoneData) =>
    this.client.post('/ciam/mfa/phone', data).then(extractOob, extractError);

  verifyMfa = (data: IBffVerifyMfa) =>
    this.client
      .post('/ciam/mfa/verify', data)
      .then(extractNothing, extractError);
}

const singleton = function <Out>(fn: () => Promise<Out>) {
  const ref: { current: null | Promise<Out> } = {
    current: null,
  };

  return () => {
    if (!ref.current) {
      ref.current = fn().then(
        (data) => {
          ref.current = null;
          return data;
        },
        (e) => {
          ref.current = null;
          throw e;
        },
      );
    }

    return ref.current;
  };
};
