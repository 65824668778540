import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { AnimatePresence } from 'framer-motion';
import { SessionProvider } from 'next-auth/react';
import { AppProps } from 'next/app';
import { ThemeProvider } from 'react-jss';
import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CiamClient } from '../client/CiamClient/CiamClient';
import { Notifications } from '../components/molecules/Notifications';
import { ClientProvider } from '../contexts/ClientContext';
import { initTranslation } from '../i18n';
import { dsoTheme, resellerTheme } from '../theme';

function Page({ Component, pageProps: { lang = 'en', ...props } }: AppProps) {
  if (i18n.locale !== lang) {
    initTranslation({ i18n, locale: lang });
  }
  const queryClient = new QueryClient();

  const [client] = React.useState(new CiamClient());
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  return (
    <ThemeProvider
      theme={props.businessUnit === 'reseller' ? resellerTheme : dsoTheme}
    >
      <I18nProvider i18n={i18n}>
        <Notifications>
          <QueryClientProvider client={queryClient}>
            <SessionProvider session={props.session}>
              <ClientProvider value={client}>
                <AnimatePresence initial={false}>
                  <Component {...props} />
                </AnimatePresence>
              </ClientProvider>
            </SessionProvider>
          </QueryClientProvider>
        </Notifications>
      </I18nProvider>
    </ThemeProvider>
  );
}

export default Page;
